import React, { useState } from "react";
import { TextField, Typography } from "@mui/material";
import useAuth from "../../hooks/useAuth";
import {
  ErrorMessage,
  NewAccountButton,
} from "../../shared-components/CommonStyles";
import useUser from "../../hooks/useUser";
import MainShared from "../../shared-components/MainShared";

const Login = () => {
  const { login: loginEndpoint } = useAuth();
  const { setterUsername } = useUser();
  const [error, setError] = useState();
  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const login = (e) => {
    e.preventDefault();
    loginEndpoint(
      {
        username: loginData.username,
        password: loginData.password,
      },
      setIsLoadingButton,
      setError,
      setterUsername
    );
  };
  // console.log({ loginUsername: setterUsername });
  return (
    <MainShared>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "40%",
          height: "80%",
          // backgroundColor: "red",
        }}
      >
        <form
          onSubmit={login}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: "black",
              fontFamily: "sans-serif",
              fontSize: "30px",
              marginBottom: "10px",
              fontWeight: "600",
            }}
          >
            Inicio de sesión
          </Typography>
          <div
            style={{
              width: "100%",
              display: "flex",
              margin: "8px 0 8px 0",
              // alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{
                position: "relative",
                marginBottom: "4px",
                marginLeft: "2px",
              }}
            >
              Nombre de usuario:
            </Typography>
            <TextField
              sx={{ marginBottom: "14px" }}
              name="username"
              size="small"
              label="Ingrese su usuario"
              type="text"
              variant="filled"
              value={loginData.username}
              alignItems="center"
              onChange={onChangeInput}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              margin: "8px 0 8px 0",
              // alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{
                position: "relative",
                marginBottom: "4px",
                marginLeft: "2px",
              }}
            >
              Contraseña:
            </Typography>
            <TextField
              sx={{ marginBottom: "14px" }}
              name="password"
              size="small"
              label="Ingrese su contraseña"
              type="password"
              variant="filled"
              value={loginData.password}
              onChange={onChangeInput}
            />
          </div>
          <ErrorMessage>{error ? `*${error}` : ""}</ErrorMessage>
          <NewAccountButton
            type="submit"
            variant="contained"
            loading={isLoadingButton}
          >
            Iniciar sesión
          </NewAccountButton>
        </form>
      </div>
    </MainShared>
  );
};

export default Login;
