import React, { useState } from "react";
import useAxios from "../../hooks/useAxios";
import { useQuery } from "react-query";
import UserInfo from "../../shared-views/userInfo/UserInfo";
import { DataGrid } from "@mui/x-data-grid";
import Loading from "../../shared-components/Loading";
import { formatData, getColumns } from "./utils";

const Requests = () => {
  const { getUsers } = useAxios();
  const [usersState, setUsersState] = useState({
    state: "REQUESTS",
    data: null,
  });
  const { data, isLoading, refetch } = useQuery(["usersRequest"], getUsers);
  const getRender = () => {
    // console.log({ usersState });
    switch (usersState.state) {
      case "USER_INFO":
        return (
          <UserInfo
            userData={usersState.data}
            refetch={refetch}
            handleBack={() => setUsersState({ state: "REQUESTS", data: null })}
          />
        );
      case "REQUESTS":
        return (
          <div style={{ overflow: "hidden", height: "100%", width: "100%" }}>
            <DataGrid
              rows={formatData(data) || []}
              sx={{
                "& .MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                "& .MuiDataGrid-cell:focus-within": {
                  outline: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  overflowX: "hidden",
                },
              }}
              components={{
                NoRowsOverlay: () => {
                  if (isLoading) {
                    return <Loading />;
                  } else {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ marginTop: "20px" }}>
                          No hay ninguna solicitud
                        </span>
                      </div>
                    );
                  }
                },
              }}
              disableRowSelectionOnClick
              disableMultipleRowSelection
              cells
              columns={getColumns(setUsersState)}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[8]}
            />
          </div>
        );
      default:
        return <Loading />;
    }
  };

  return <>{isLoading ? <Loading /> : getRender()}</>;
};

export default Requests;
