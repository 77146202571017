import axios from "axios";
import { sha3_512 } from "js-sha3";
import { createContext, useEffect, useState } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
import useAxios from "../hooks/useAxios";
import {
  arrayBufferToBase64,
  getRandomInt,
  numberToBytes,
} from "../utils/utils";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const { client, isAuth, setIsAuth } = useAxios();
  const { getItem, removeItem, setItem } = useLocalStorage();

  const login = (loginPayload, loading, error, username) => {
    loading(true);
    let bytesPassword = new TextEncoder();
    bytesPassword = bytesPassword.encode(loginPayload.password);
    // // console.log({ bytesPassword });
    let hashedPwBytes = sha3_512.arrayBuffer(bytesPassword);
    // // console.log({ hashedPwBytes });
    let hashedPwString = arrayBufferToBase64(hashedPwBytes);
    // // console.log({ hashedPwString });
    let hashedPwStringBytes = new TextEncoder().encode(hashedPwString);
    // // console.log({ hashedPwStringBytes });
    let nonce = getRandomInt();
    // // console.log({ nonce });
    let nonceBytes = numberToBytes(nonce);
    // // console.log({ nonceBytes });
    let pwAndNonce = [...hashedPwStringBytes, ...nonceBytes];
    // // console.log({ pwAndNonce });
    let hashedPwAndNonceBytes = sha3_512.arrayBuffer(pwAndNonce);
    // // console.log({ hashedPwAndNonceBytes });
    let hashedPwAndNonceString = arrayBufferToBase64(hashedPwAndNonceBytes);
    // // console.log({ hashedPwAndNonceString });
    let loginPayloadHashed = {
      username: loginPayload.username,
      password: hashedPwAndNonceString,
      nonce: nonce,
    };
    client
      ?.post("/api/auth/login_admin", loginPayloadHashed)
      .then((res) => {
        setItem("jwt", res);
        setIsAuth(true);
        username(loginPayload.username);
        loading(false);
      })
      .catch((err) => {
        let errorMessage = err?.response?.data;
        loading(false);
        error(errorMessage);
      });
  };

  const logout = async () => {
    client?.post("/api/users/logout").then(() => {
      removeItem("jwt");
    });
    setIsAuth(false);
    //TODO
  };

  useEffect(() => {
    const jwt = getItem("jwt");
    if (jwt) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, []);
  // console.log({ isAuth });
  return (
    <AuthContext.Provider
      value={{
        isAuth,
        login,
        logout,
      }}
    >
      {isAuth !== null && children}
    </AuthContext.Provider>
  );
};
export default AuthProvider;
